import { SelectionChangedEvent } from "ag-grid-community";
import { PortEntity } from "domain/entity/Port/PortEntity";
import { INITIAL_PORT_COL_DEF } from "presentation/constant/Port/PortColumnDefinition";
import { usePortVM } from "presentation/hook/Port/usePortVM";
import { usePortTracked } from "presentation/store/Port/PortProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useMemo, useRef } from "react";
const PortTablePanel = () => {
    const gridRef: any = useRef(null);
    const [portState] = usePortTracked();
    const portVM = usePortVM();

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();
        portVM.updateSelectedRows(selectedRows);
    }, [portVM])



    const handleRowDoubleClick = useCallback((entity: PortEntity) => {
        portVM.onRowDoubleClick(entity);
    }, [portVM])

    const memoPortTable = useMemo(() => {

        return (
            <NbisTable
                id='port-table'
                isNewColumnSetting={true}
                columns={INITIAL_PORT_COL_DEF}
                data={portState.portList ?? []}
                showPaginator={false}
                editable={false}
                showAddIcon={false}
                showDeleteButton={false}
                showReloadIcon={false}
                isScrollHighlighted={true}
                selectionMode={false}
                isRowHighligted={true}
                handleSelectionChanged={handleSelectionChange}
                onRowDoubleClick={(e: any, entity: PortEntity) => handleRowDoubleClick(entity)}
                gridHeight="customHeight"
                customHeight="calc(100vh - 125px)"
                ref={gridRef}
            />
        );
    }, [portState.portList, handleRowDoubleClick, handleSelectionChange])

    return <><TableWrapper>
        {memoPortTable}
    </TableWrapper>
    </>;
}

export default memo(PortTablePanel);
